.App-logo {
  animation: App-logo-spin infinite 2s ease-in-out;
}

.App > main {
  background-color: #ddd;
  padding-top: 3vmin;
}

.App > footer {
  margin-top: 6vh;
  width: 100%;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.beating {
  animation: beating-pulse infinite 0.8s ease-in-out;
  display: inline-block;
}

@keyframes beating-pulse {
  from {
    transform: scale(1.0);
  }
  to {
    transform: scale(1.2);
  }
}

.js-copytextarea {
  position: absolute;
  left: -500px;
  top: -500px;
}

.navbar-actions {
  float:right;
}

#message-out {
  height: 20vh;
}

#message-in {
  resize: none;
  height: 20vh;
  background-color: #333;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: #DD4A68;
}

.box > .title {
  padding-top: 3vh;
  padding-bottom: 1vh;
  font-size: 16px;
  font-weight: 400;
}

.loading-spinner {
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  font-size: 300px;
}

.loading-spinner > i {
  font-size: 120px;
  color: #444;
}

@-moz-keyframes spin {
    to { -moz-transform: rotate(-360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(-360deg); }
}
@keyframes spin {
    to {transform:rotate(-360deg);}
}

.spin {
    animation: spin 2500ms linear infinite;
}

.qrcode {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  margin-bottom: 2vh;
}

#scan-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

#scan-button > i {
  margin-left: 5px;
}

#qr-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

#qr-button > i {
  margin-left: 5px;
}
